<template>
  <div class="main">
    <heand :tagnum="tagnum"></heand>
    <div class="enterprise clearfix">
      <div class="left">
        <ul class="proje_ul">
          <!-- <li>
            <strong>
              <i style="color: red; margin: 0 5px">*</i>您公司的名称：
            </strong>
            <span>
              <el-input v-model="company" placeholder="请填写"></el-input>
            </span>
          </li> -->
          <li>
            <strong>
              <i style="color: red; margin: 0 5px">*</i>所属行业：
            </strong>
            <!-- <span>
              <el-input v-model="industry" placeholder="请填写"></el-input>
            </span> -->
            <el-select v-model="industry" placeholder="请选择">
              <el-option
                v-for="item in listoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </li>
          <li>
            <strong>
              <i style="color: #fff; margin: 0 5px">*</i>去年营收：
            </strong>
            <span>
              <el-input
                type="number"
                v-model="revenue"
                placeholder="请填写"
              ></el-input
              >万元
            </span>
          </li>
          <li>
            <strong>
              <i style="color: #fff; margin: 0 5px">*</i>去年税收：
            </strong>
            <span>
              <el-input
                type="number"
                v-model="tax"
                placeholder="请填写"
              ></el-input
              >万元
            </span>
          </li>
          <li>
            <strong>
              <i style="color: red; margin: 0 5px">*</i>主营产品：
            </strong>
            <span>
              <el-input v-model="product" placeholder="请填写"></el-input>
            </span>
          </li>
          <li>
            <strong>
              <i style="color: red; margin: 0 5px">*</i>落地投资预算：
            </strong>
            <span>
              <el-input
                v-model="budget"
                type="number"
                placeholder="请填写"
              ></el-input
              >万元
            </span>
          </li>
          <li>
            <strong> <i style="margin: 0 8px"></i>意向选址区域： </strong>
            <span>
              <el-select v-model="address" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </span>
          </li>
          <li>
            <strong>
              <i style="color: red; margin: 0 8px">*</i>选址偏好：
            </strong>
            <span>
              <strong>
                <el-checkbox v-model="checked1">政策扶持力度</el-checkbox>
              </strong>
              <strong>
                <el-checkbox v-model="checked2">产业集聚度</el-checkbox>
              </strong>
              <strong>
                <el-checkbox v-model="checked3">交通便利度</el-checkbox>
              </strong>
              <strong>
                <el-checkbox v-model="checked4">生活配套</el-checkbox>
              </strong>
              <strong>
                <el-checkbox v-model="checked5">园区配套服务</el-checkbox>
              </strong>
              <!-- <strong>
                <el-checkbox v-model="checked6">地区经济发展</el-checkbox>
              </strong> -->
            </span>
          </li>
          <li>
            <b class="chengzi_btn" @click="sendsmart()">提交</b>
          </li>
        </ul>
      </div>
      <div class="right">
        <baidu-map
          v-if="showmap"
          class="bm-view"
          :center="center"
          :double-click-zoom="double"
          :zoom="zoom"
        >
          <bm-control>
            <button @click="addZoom()">还原</button>
          </bm-control>
          <bm-label
            v-for="(item, index) in district"
            :key="index"
            :content="item.name"
            :position="item.jwd"
            :labelStyle="{ color: 'red', fontSize: '12px' }"
          />
          <bm-marker
            v-for="(item, index) in alilte"
            :key="'one' + index"
            :position="item.jwd"
            :dragging="false"
            @mouseout="out"
            @mouseover="hover"
            :title="item.name"
          ></bm-marker>
          <bm-boundary
            v-for="item in district"
            :key="item.id"
            :name="item.name"
            :strokeWeight="1"
            strokeColor="blue"
            fillColor="#1F61F8"
            @click="gotocenter(item.jwd, item.name)"
          ></bm-boundary>
        </baidu-map>

        <baidu-map
          v-if="!showmap"
          class="bm-view"
          :center="center2"
          :zoom="zoom2"
        >
          <bm-control>
            <button @click="addZoom()">还原</button>
          </bm-control>
          <bm-marker
            v-for="(item, index) in alilte"
            :key="index"
            :position="item.jwd"
            :dragging="false"
            @mouseout="out"
            @mouseover="hover"
          ></bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
import { smart, smartmap, map, downList } from "@/api/index";
export default {
  data() {
    return {
      tagnum: 4,
      // company: "",
      industry: "",
      revenue: "",
      tax: "",
      product: "",
      budget: "",
      address: "",
      options: [
        { value: "全部" },
        { value: "小店区" },
        { value: "迎泽区" },
        { value: "杏花岭区" },
        { value: "尖草坪区" },
        { value: "万柏林区" },
        { value: "晋源区" },
        { value: "清徐县" },
        { value: "阳曲县" },
        { value: "娄烦县" },
        { value: "古交市" },
        { value: "山西省转型综合改革示范区" },
        { value: "中北高新区" },
        { value: "西山生态文化旅游示范区" },
        { value: "清徐经济开发区" },
        { value: "阳曲现代农业产业示范区" },
      ],
      center: { lng: 112.549, lat: 37.957 }, //经纬度
      zoom: 10, //地图展示级别
      color: "blue",
      num: 130,
      showmap: true,
      address: "",
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      double: false,
      district: [],
      alilte: [],
      listoptions: [],
    };
  },
  created() {
    this.getsmasrtmap();
    this.getmap();
    this.getdownList();
  },
  watch:{
    product:function(){
    this.product = this.product.replace(/[^\u4E00-\u9FA5]/g,'');
//  onkeyup="value=value.replace(/[^\u4E00-\u9FA5]/g,'')"
   },
  },
  methods: {
    // downList
    async getdownList() {
      const res = await downList();
      this.listoptions = res;
    },
    sendsmart() {
      let preference = [];
      preference.push(this.checked1);
      preference.push(this.checked2);
      preference.push(this.checked3);
      preference.push(this.checked4);
      preference.push(this.checked5);
      preference.push(this.checked6);
      if(this.address=="全部"){
        this.address=''
      }
      var data = {
        // company: this.company,
        industry: this.industry,
        revenue: this.revenue,
        tax: this.tax,
        product: this.product,
        budget: this.budget,
        address: this.address,
        preference: preference,
      };
      console.log(data,777777)
      
      if( this.industry == ""){
        return this.$message({
            message: "所属行业不能为空",
            type: "error",
          });
      }
      if(this.tax){
        console.log(this.revenue,'<',this.tax)
        console.log(Number(this.revenue)<Number(this.tax))
          if(Number(this.revenue) <Number(this.tax)){
            return this.$message({
            message: "税收不能大于营收",
            type: "error",
          });
        }
      }
      console.log(this.product)
      if( this.product == ""){
        return this.$message({
            message: "主营产品不能为空",
            type: "error",
          });
      }
      if( this.budget == ""){
        return this.$message({
            message: "落地投资预算不能为空",
            type: "error",
          });
      }
      var vilddata =  preference.some(r=>r)
      console.log(vilddata)
      if(!vilddata){
        return  this.$message({
            message: "选址偏好不能为空",
            type: "error",
          });
      }
      // if (
      //   // this.company == "" ||
      //   this.industry == "" 
        // ||
        // this.revenue == "" ||
        // this.tax == "" ||
        // this.budget == ""
      // ) {
      //   this.$message({
      //     message: "请填写全部数据",
      //     type: "error",
      //   });
      // } else {
        let href = this.$router.resolve({
          name: "selectionaddjiany",
          query: data,
        });
        window.open(href.href, "_blank");
      // }
    },
    async getsmasrtmap(name) {
      const res = await smartmap({
        district: name,
      });
      this.alilte = res;
    },
    async getmap() {
      const res = await map({
        inid: 1,
        cate: "1",
      });
      this.district = res.df;
    },
    gotocenter(index, name) {
      this.showmap = false;
      this.center2 = index;
      this.color = "transparent !important";
      this.zoom2 = 13;
      this.getsmasrtmap(name);
    },
    hover() {
      this.show = true;
    },
    out() {
      this.show = false;
    },
    addZoom() {
      this.showmap = true;
      this.zoom = 10;
      this.getsmasrtmap();
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background: rgb(243, 243, 243);
}
.enterprise {
  padding: 10px 30px;
}
.left {
  float: left;
  background: #fff;
  width: 40%;
  height: 87vh;
}
.right {
  background: #fff;
  width: calc(100% - 40% - 10px);
  height: 83vh;
  float: right;
  padding: 20px;
}
.seladd_tit {
  font-size: 16px;
  background: url("../../assets/selectionadd/titles.png") no-repeat center;
  height: 83px;
  line-height: 70px;
  text-align: center;
  color: #fff;
  font-weight: normal;
  margin-top: 35px;
}
.seladd_tit img {
  margin-right: 5px;
  vertical-align: middle;
}
.seladd_dl {
  margin: 15px 40px;
  height: 150px;
  padding: 20px 5px 0 20px;
  background: #ffffff;
  border: 1px solid #feb683;
  border-radius: 4px;
  overflow: hidden;
  zoom: 1;
}

.seladd_dl dt {
  width: 51px;
  float: left;
}
.seladd_dl dd {
  float: right;
  width: calc(100% - 61px);
}
.seladd_dl dd p {
  text-align: left;
  color: #666;
  font-size: 14px;
  line-height: 26px;
}
.seladd_dl dd ul {
  margin-top: 25px;
  margin-left: -10px;
  overflow: hidden;
  zoom: 1;
}
.seladd_dl dd li {
  float: left;
  width: 38%;
  line-height: 26px;
  list-style: none;
  font-size: 14px;
  color: #666;
  text-align: left;
  background: url("../../assets/selectionadd/tx_icon.png") no-repeat left top;
  padding-left: 27px;
}
.seladd_dl dd li:last-child {
  width: 46%;
  background: url("../../assets/selectionadd/tel_icon.png") no-repeat left top;
}
.proje_ul {
  padding: 60px 0 0 60px;
  overflow: hidden;
  zoom: 1;
  margin-bottom: 15px;
}
.proje_ul li {
  font-size: 14px;
  text-align: left;
  line-height: 34px;
  list-style: none;
  margin-bottom: 15px;
  overflow: hidden;
  zoom: 1;
}
.proje_ul li strong {
  font-weight: normal;
  display: block;
  width: 115px;
  float: left;
}
.proje_ul li span {
  display: block;
  float: left;
  width: 68%;
}
.proje_ul li span strong {
  display: block;
  width: 33%;
  min-width: 108px;
  float: left;
}
.chengzi_btn {
  background: #fe6a00;
  color: #fff;
  padding: 8px 30px;
  margin-left: 115px;
  cursor: pointer;
}
/deep/.el-input {
  width: 82%;
  height: 32px;
  line-height: 32px;
  margin-right: 6px;

  input {
    height: 32px;
    line-height: 32px;
    border-radius: 0;
    color: #333;
  }
  ::-webkit-input-placeholder {
    color: #333;
  }
}

/deep/.el-input__icon {
  line-height: 32px;
}

/deep/.el-select {
  height: 30px;
  input {
    width: 100%;
    height: 30px;
    background: #ffffff;
    border: 1px solid #dddddd;
  }
  /deep/.el-input__icon {
    line-height: 30px !important;
  }
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fe6a00;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fe6a00;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #fe6a00;
  border-color: #fe6a00;
}
/deep/.el-checkbox__inner:hover {
  border-color: #fe6a00;
}
.bm-view {
  width: 100%;
  height: 100%;
  /deep/.BMapLabel {
    background-color: transparent !important;
    border: 0 !important;
    color: rgb(70, 17, 17) !important;
  }
}
</style>